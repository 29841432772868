.navigationMenuContainer {
    padding-top: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
	background-color: rgba(0, 0, 0, 0.45) !important;
	background-image: none !important;
	backdrop-filter: blur(2px);
}

.openHideMenuButton {
    font-family: 'primeicons';
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    border: none;
    appearance: none;
}

.openHideMenuButton:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    background-color: rgb(155, 2, 2);
    width: 0;
    transition: width 0.2s ease-in-out;
}

.openHideMenuButton:active, .openHideMenuButton:hover {
    color: rgb(248, 67, 67) !important;
    background-color: #000000 !important;
}

.openHideMenuButton:hover:before {
    width: 100%;
}

.navigationMenu {
    display: flex;
    justify-content: center;
    gap: var(--standardGap);
    font-family: 'Future Rot';
    align-items: center;
}

.navigationButton {
    border: none;
    min-width: 10rem;
    flex-wrap: wrap;
    font-family: 'Future Rot';
    overflow: inherit;
    appearance: none;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    min-height: 60px;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    justify-content: center;
    position: relative;
}

.navigationButton:disabled {
    pointer-events: none;
}

.navigationButton:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    background-color: rgb(155, 2, 2);
    width: 0;
    transition: width 0.2s ease-in-out;
}

.navigationButton:hover:before {
    width: 100%;
}

.navigationButton:hover {color: rgb(248, 67, 67);}

.navigationButton:active {
    box-shadow: none;
    transform: translateY(0);
}

@media (max-width: 1210px) {
    .navigationMenu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--standardGap);
        font-family: 'Future Rot';
        align-items: center;
    }
}
