.hall-of-fame-suggestions {
    display: flex;
    flex-direction: column;
}

.hall-of-fame-suggestions .card {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow: auto;
}

.hall-of-fame-suggestions .centered-text {
    margin: auto;
}

.hall-of-fame-suggestions .card .p-toolbar {
    background-color: rgba(0, 0, 0, 0.85) !important;
    border: 0;
    width: 100%;
}

.hall-of-fame-suggestions .card .p-toolbar button {
    background-color: #000;
    color: white;
    border: none;
}

.hall-of-fame-suggestions .card .p-toolbar button:hover,
.hall-of-fame-suggestions .card .p-toolbar button:active {
    background-color: #000 !important;
    color: #ff1313 !important;
    transition: 0.1s;
    border: none;
}

.hall-of-fame-suggestions .p-datatable .p-datatable-thead > tr > th,
.hall-of-fame-suggestions .p-datatable .p-column-header-content {
    /*backdrop-filter: blur(10px);*/
    background-color: rgba(0, 0, 0, 0.85) !important;
    border: none;
    appearance: none;
}

.hall-of-fame-suggestions .p-datatable .p-sortable-column:hover,
.hall-of-fame-suggestions
    .p-datatable
    .p-sortable-column
    .p-column-header-content:hover {
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: rgb(248, 67, 67) !important;
}

.hall-of-fame-suggestions .card .p-toolbar-group-start .p-toolbar-group-left {
    gap: 10px;
    margin: auto;
}

.hall-of-fame-suggestions .card .p-toolbar,
.p-toolbar-group-center {
    margin: 0;
}

.hall-of-fame-suggestions .p-datatable-scrollable .p-datatable-wrapper {
    overflow: none;
}

.hall-of-fame-suggestions .p-datatable {
    width: 90%;
    background-color: transparent;
    background: transparent;
    padding-top: 20px;
    margin: auto;
    font-family: 'Future Rot';
    font-size: 1.1rem;
    backdrop-filter: blur(10px);
    -webkit-text-stroke: 1px black;
}

.hall-of-fame-suggestions .p-datatable .p-datatable-tbody > tr {
    background: transparent;
    border: none;
}
