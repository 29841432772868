.github-repo {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.github-repo .card {
	height: 100%;
	display: flex;
}

.github-repo a {
	font-family: "Future Rot";
	font-size: 3rem;
	text-decoration: none;
	color: wheat;
	-webkit-text-stroke: 3px red;
}

.github-repo .centered-text {
	padding-top: var(--standardPadding);
	font-size: 3rem;
	margin: auto;
	-webkit-text-stroke: 1px grey;
}
