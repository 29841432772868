.game-stats {
    display: flex;
    flex-direction: column;
}

.game-stats .card {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}

.game-stats .centered-text {
    margin: auto;
}

.game-stats .p-datatable-scrollable .p-datatable-wrapper {
    overflow: auto;
}

.game-stats .p-datatable {
    margin: auto;
    font-size: 0.8rem;
    font-family: 'Montserrat', 'Noto Sans', sans-serif;
    background: radial-gradient(#000 0%, #222e3a 100%);
    -webkit-text-stroke: 1px gray;
    width: 98%;
}

.game-stats .p-datatable .p-datatable-tbody > tr {
    font-size: large;
    background: transparent;
    border: none;
}

.game-stats .user-data img {
    width: 32px;
    height: 32px;
}

.game-stats .user-data {
    display: flex;
    flex-direction: row;
}

.game-stats .user-data span {
    margin: auto auto auto 1rem;
    text-decoration: none;
    font-size: 1.3rem;
}

.game-stats .user-data .user-nickname span {
    text-decoration: none;
}

.game-stats .p-accordion-header-text {
    width: 100%;
}

.game-stats .p-accordion-content {
    /* The unrolled stats content*/
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background-color: rgb(31, 35, 40) !important;
}

.game-stats .p-input-icon-left {
    margin: auto auto 0.5rem;
}

.game-stats .accordion-column {
    font-size: 1.3rem;
}

.game-stats .accordion-column > div {
    font-size: 0.8rem;
}

.game-stats .accordion-header-data {
    margin: auto 0 auto 2rem;
}

.game-stats .p-accordion .p-accordion-header-link {
    padding: 0.2rem 0.6rem;
}

.game-stats .p-datatable .p-datatable-tbody > tr > td {
    padding: 0rem 0rem;
}

.game-stats .p-button {
    height: 2rem;
    background-color: #000;
    color: white;
    border: none;
    -webkit-text-stroke: 0px;
}

.game-stats .p-button:active,
.game-stats .p-button:hover {
    height: 2rem;
    background-color: #000;
    color: #ff1313 !important;
    transition: 0.1s;
    border: none;
}
