@import "primereact/resources/themes/bootstrap4-dark-purple/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		"Noto Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Hack",
		"Source Code Pro", monospace;
}

html {
	height: 100%;
	width: 100%;
}

body {
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
