.hall-of-fame {
    display: flex;
    flex-direction: column;
}

.hall-of-fame .card {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow: auto;
}

.hall-of-fame .centered-text {
    margin: auto;
}

.hall-of-fame .card .p-toolbar {
    width: 100%;
}

.hall-of-fame .card .p-toolbar-group-start .p-toolbar-group-left {
    gap: 10px;
    margin: auto;
}

.hall-of-fame .card .p-toolbar,
.p-toolbar-group-center {
    background-color: rgba(0, 0, 0, 0.85) !important;
    margin: 0;
    border: none;
}

.hall-of-fame .card .p-toolbar button {
    background-color: #000;
    color: white;
    border: none;
}

.hall-of-fame .card .p-toolbar button:hover,
.hall-of-fame .card .p-toolbar button:active {
    background-color: #000 !important;
    color: #ff1313 !important;
    transition: 0.1s;
    border: none;
}

.hall-of-fame .p-datatable .p-datatable-thead > tr > th,
.hall-of-fame .p-datatable .p-column-header-content {
    /*backdrop-filter: blur(10px);*/
    background-color: rgba(0, 0, 0, 0.85) !important;
    border: none;
    appearance: none;
}

.hall-of-fame .p-datatable .p-sortable-column:hover,
.hall-of-fame .p-datatable .p-sortable-column .p-column-header-content:hover {
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: rgb(248, 67, 67) !important;
}

.hall-of-fame .p-datatable-scrollable .p-datatable-wrapper {
    overflow: auto;
}

.hall-of-fame .p-datatable {
    width: 90%;
    background-color: transparent;
    background: transparent;
    padding-top: 20px;
    margin: auto;
    font-family: 'Future Rot';
    font-size: 1.1rem;
    backdrop-filter: blur(10px);
    -webkit-text-stroke: 1px black;
}

.hall-of-fame .p-datatable .p-datatable-tbody > tr {
    background: transparent;
    border: none;
}

.hall-of-fame .p-datatable-tbody .voting {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--standardGap);
}

.hall-of-fame [class*='p-button'] {
    transition: all 0.5s;
}

.hall-of-fame [class*='vote-up-button'] {
    background: rgb(0, 220, 0);
    border: none;
}

/* No blue colour on pointer's hover */
.hall-of-fame [class*='vote-up-button']:hover {
    background: forestgreen;
}

/* When you click and hold the button, and move the cursor away still holding the button */
.hall-of-fame [class*='vote-up-button']:active:focus {
    background: forestgreen;
}

.hall-of-fame [class*='self-voted'] {
    box-shadow: inset 1px 5px 20px black;
}

.hall-of-fame [class*='vote-down-button'] {
    background: rgb(220, 0, 0);
    border: none;
}

.hall-of-fame [class*='vote-down-button']:hover {
    background: rgb(139, 34, 34);
}

.hall-of-fame [class*='vote-down-button']:active:focus {
    background: rgb(139, 34, 34);
}

.hall-of-fame [class*='p-button-icon p-c pi pi-trash'] {
    font-size: 1.2rem;
    -webkit-text-stroke: 0px;
}

.hall-of-fame [class*='p-button-icon p-c pi pi-pencil'] {
    font-size: 1.2rem;
    -webkit-text-stroke: 0px;
}

.hall-of-fame .p-datatable button {
    color: white;
    border: none;
}

.hall-of-fame .p-datatable button:hover,
.hall-of-fame .p-datatable button:active {
    border: none;
}

.hall-of-fame .p-datatable button.mr-2 {
    background-color: #000 !important;
}

.hall-of-fame .p-datatable button.mr-2:hover,
.hall-of-fame .p-datatable button.mr-2:active {
    background-color: #000 !important;
    color: rgb(248, 67, 67) !important;
}

.hall-of-fame .p-datatable button.p-button-danger {
    background: rgb(172, 21, 21);
    margin-left: 15px;
}

.hall-of-fame .p-datatable button.p-button-danger:hover,
.hall-of-fame .p-datatable button.p-button-danger:active {
    background-color: red !important;
    transition: 0.2s;
    color: white !important;
}

.p-dialog {
    height: 80%;
    width: 80%;
}
