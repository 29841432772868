.user-details {
	display: flex;
}

.user-details img {
	display: inline-block;
	width: 64px;
	height: 64px;
}

.user-details .card {
	display: flex;
	flex-direction: column;
}

.navigation-menu .user-details span {
	font-family: "Future Rot";
	display: block;
}
